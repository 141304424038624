import { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import NavigateToSteps from "./NavigateToSteps";
import { CheckboxButton } from "../CheckboxButton";
import { useDispatch } from "react-redux";
import {
  setAssessmentQuestion,
  setValidStatusBar,
} from "../../store/assessmentTask/assessmentTask.actions";
import CodingSelect from "../_elements/CodingSelect";
import { validateFormField } from "../../config/utils";
import fetchData from "../../hook/fetchData";
import { getEnv } from "@urecruits/api";
const {API_ASSESSMENT}=getEnv()

const HomeTaskStepOne = (props) => {
  const {
    setIsNameQuestion,
    homeQuestion,
    requiredFieldByCreateButton,
    validFields,
    setValidFields,
  } = props;

  const [isCheckedTestCases, setIsCheckedTestCases] = useState(
    homeQuestion.isIncludeTestCases
  );

  const [languagesData, setLanguagesData] = useState<any>();
  const [validFieldsButtonEvent, setValidFieldsButtonEvent] = useState(false);
  const [validFieldsName, setValidFieldsName] = useState(false);
  const [validFieldsDescription, setValidFieldsDescription] = useState(false);
  const [dialogCreateDatabase, setDialogCreateDatabase] = useState(false);
  const [packageOptions, setPackageOptions] = useState([]);
  const [databaseOptions, setDatabaseOptions] = useState([]);
  const [databaseDetails, setDatabaseDetails] = useState<any>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setAssessmentQuestion({
        ...homeQuestion,
        databaseId:{id:homeQuestion.database},
      })
    );
  }, [dispatch, homeQuestion]);

  useEffect(() => {
    dispatch(
      setAssessmentQuestion({
        ...homeQuestion,
        isIncludeTestCases: isCheckedTestCases,
      })
    );
  }, [dispatch, isCheckedTestCases, homeQuestion]);

  const handleCheckTestCases = () => {
    setIsCheckedTestCases(!homeQuestion.isIncludeTestCases);
  };

  const handleNext = () => {
    isCheckedTestCases
      ? navigate("/coding-assessments/take-home-task/content-question")
      : navigate("/coding-assessments/take-home-task/test-cases");
  };

  const onHandleNameQuestion = (e) => {
    dispatch(
      setAssessmentQuestion({
        ...homeQuestion,
        nameQuestion: e.target.value,
      })
    );
    setIsNameQuestion(e.target.value);
    setValidFieldsName(
      e.target.value === "Untitled question" || e.target.value === ""
    );
  };

  useEffect(() => {
    if (!languagesData) {
      fetchData(`${API_ASSESSMENT}/api/languages`, setLanguagesData)
        .then((res) => {
          return res.data})
        .then((res) => setLanguagesData(res));
    }
  }, [languagesData]);

  useEffect(() => {
    fetchData(
      `${API_ASSESSMENT}/api/take-home-task/packages`,
      setPackageOptions
    )
      .then((res) => res.data)
  }, []);

  useEffect(() => {
    if(!dialogCreateDatabase){
      fetchData(
        `${API_ASSESSMENT}/api/assesment-database`,
        setDatabaseOptions
      ).then((res) => res.data);
    }
  }, [dialogCreateDatabase]);


  useEffect(() => {
    setValidFields(
      !(
        homeQuestion.nameQuestion === "" ||
        homeQuestion.languages === "" ||
        homeQuestion.questionDescription === ""
      ) &&
        !(
          homeQuestion.nameQuestion === "Untitled question" ||
          homeQuestion.languages === null ||
          homeQuestion.questionDescription === null
        )
    );
  }, [
    homeQuestion.languages,
    homeQuestion.nameQuestion,
    homeQuestion.questionDescription,
    setValidFields,
  ]);

  useEffect(() => {
    dispatch(
      setValidStatusBar({ questionId: homeQuestion.id, stepOne: validFields })
    );
  }, [dispatch, homeQuestion.id, validFields]);

  const onCreateDatabase = () => {
    setDialogCreateDatabase(true);
  };


  return (
    <NavigateToSteps>
      <div className="task-home">
        <form onSubmit={handleNext}>
          <div className="task-home__container">
            <h3 className="task-home__container__title">
              01. Question Details
            </h3>
            <label className="label-task-font" htmlFor="task-home-label">
              <span>
                Name of Question <span className="asterisk-sign">*</span>
              </span>
            </label>
            <input
              className={`live-task-input ${
                validateFormField(
                  homeQuestion.nameQuestion,
                  validFieldsName,
                  requiredFieldByCreateButton || validFieldsButtonEvent
                ) && "error"
              }`}
              type="text"
              maxLength={120}
              value={
                homeQuestion.nameQuestion === "Untitled question"
                  ? ""
                  : homeQuestion.nameQuestion
              }
              placeholder="Enter question title"
              onChange={onHandleNameQuestion}
            />
            {validateFormField(
              homeQuestion.nameQuestion,
              validFieldsName,
              requiredFieldByCreateButton || validFieldsButtonEvent
            ) && <div className="error-data-one">Invalid data</div>}
            <div style={{ marginTop: "25px", marginBottom: "5px" }}>
              <CheckboxButton
                style={{ paddingRight: "25px", top: "-2px" }}
                type="checkbox"
                name={"item"}
                handleClick={handleCheckTestCases}
                isChecked={homeQuestion.isIncludeTestCases}
              />
              <span
                style={{
                  paddingLeft: "15px",
                  fontSize: "14px",
                  color: homeQuestion.isIncludeTestCases ? "" : "#999EA5",
                }}
              >
                Include Test Cases
              </span>
            </div>
            <div className="two-input-task">
              <div className="two-input-task-select-bar">
                <label htmlFor="live-task-label" className="label-task-font">
                  <span>
                    Language <span className="asterisk-sign">*</span>
                  </span>
                </label>
                <CodingSelect
                  validate={
                    validFieldsButtonEvent && homeQuestion.languages?.id === null
                  }
                  placeholder="Select languages"
                  options={languagesData ? languagesData : []}
                  selected={languagesData?.find(i => i.id == homeQuestion.languages?.id)}
                  setSelected={(activeLanguage) =>{
                    const lan = languagesData && languagesData?.find(i => i.name == activeLanguage)
                    if(lan){
                      dispatch(
                        setAssessmentQuestion({
                          ...homeQuestion,
                          languages: lan,
                        })
                      )
                    }
                  }
                  }
                />
              </div>
              <div className="two-input-task-select-bar">
                <label htmlFor="live-task-label" className="label-task-font">
                  Packages
                </label>
                <CodingSelect
                  placeholder="Select packages"
                  options={packageOptions}
                  selected={packageOptions?.find(i => i.id == homeQuestion.packages?.id)}
                  setSelected={(activePackage) =>{
                    const pkg = packageOptions && packageOptions?.find(i => i.name == activePackage)
                    if(pkg){
                      dispatch(
                        setAssessmentQuestion({
                          ...homeQuestion,
                          packages: pkg,
                        })
                      )
                    }
                  }
                  }
                />
              </div>
            </div>
            {/* <div className="input-button-database">
              <div className="two-input-task-select-bar input-database">
                <label htmlFor="live-task-label" className="label-task-font">
                  <span>Database</span>
                </label>
                <CodingSelect
                  placeholder="Select database"
                  options={databaseOptions}
                  selected={databaseDetails}
                    setSelected={(activeDatabase) => {
                      for (const key in activeDatabase) {
                        if (activeDatabase.hasOwnProperty("id")) {
                        dispatch(
                          setAssessmentQuestion({
                            ...homeQuestion,
                          database: {id: activeDatabase["id"]}
                        }))
                        }
                      }
                      setDatabaseDetails(
                        Object.assign({}, databaseDetails, activeDatabase)
                      );
                    }}
                />
              </div>
              <div className="button-create-database-wrapper">
                <button
                  onClick={onCreateDatabase}
                  type="button"
                  className="button-create-database"
                >
                  Create database
                </button>
                {dialogCreateDatabase && (
                  <DialogDatabase
                    homeQuestion={homeQuestion}
                    onDialog={setDialogCreateDatabase}
                  />
                )}
              </div>
            </div> */}
            <label htmlFor="live-task-label" className="label-task-font">
              Question Description <span className="asterisk-sign">*</span>
            </label>
            <textarea
              value={homeQuestion.questionDescription}
              rows={4}
              className={`live-task-input-textarea ${
                validateFormField(
                  homeQuestion.questionDescription,
                  validFieldsDescription,
                  requiredFieldByCreateButton || validFieldsButtonEvent
                ) && "error"
              }`}
              placeholder="Write question description"
              onChange={(e) => {
                dispatch(
                  setAssessmentQuestion({
                    ...homeQuestion,
                    questionDescription: e.target.value,
                  })
                );
                setValidFieldsDescription(
                  e.target.value === "" ||
                    homeQuestion.questionDescription === null
                );
              }}
            />
            {validateFormField(
              homeQuestion.questionDescription,
              validFieldsDescription,
              requiredFieldByCreateButton || validFieldsButtonEvent
            ) && <div className="error-data-one">Invalid data</div>}
            <div
              style={{ fontSize: "12px", marginTop: "20px", color: "#999EA5" }}
            >
              This description will be visible only in list of coding questions
            </div>
            <div className="first-group-button-task">
              <div>
                <button
                  onClick={(e) => {
                    !isCheckedTestCases
                      ? validFields &&
                        dispatch(
                          setAssessmentQuestion({
                            ...homeQuestion,
                            formStep: homeQuestion.formStep + 2,
                          })
                        )
                      : validFields &&
                        dispatch(
                          setAssessmentQuestion({
                            ...homeQuestion,
                            formStep: homeQuestion.formStep + 1,
                          })
                        );
                    setValidFieldsButtonEvent(true);
                    e.preventDefault();
                  }}
                  type="submit"
                  className="button-save"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </NavigateToSteps>
  );
};

export default HomeTaskStepOne;
