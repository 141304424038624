import React, { useEffect, useState } from "react";
import { navigate, Link } from "@reach/router";
import AddQuestionTask from "./AddQuestionTask";
import ProgressBar from "./ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { assessmentTaskSelectors } from "../../store/assessmentTask/assessmentTask.selectors";
import {
  DeleteTakeHomeTask,
  resetAssessmentTaskState,
  setAssessmentDescription,
  setAssessmentName, setFormQuestions,
  setQuestions
} from "../../store/assessmentTask/assessmentTask.actions";
import { getEnv } from "@urecruits/api";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
const arrowTask = require("../../images/arrowTask.svg");
const sendToDraft = require("../../images/sendToDraft.svg");
const { API_ASSESSMENT, API_RECRUITMENT }=getEnv()


import HomeTaskStepOne from "./HomeTaskStepOne";
import HomeTaskStepThree from "./HomeTaskStepThree";
import HomeTaskStepTwo from "./HomeTaskStepTwo";
import HomeTaskStepGeneral from "./HomeTaskStepGeneral";
import { CSSTransition } from "react-transition-group";
import postData from "../../hook/postData";
import DialogCreate from "./DialogCreate";
import DialogDeleteQuestion from "./DialogDeleteQuestion";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import DialogDraftQuestion from "./DialogDraftQuestion";
import cn from "classnames";
import { DataTypes } from "../../types/assessmentTask";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import fetchData from "../../hook/fetchData";

const crossQuestion = require("../../images/crossQuestion.svg");
const crossQuestionWhite = require("../../images/crossQuestionWhite.svg");
const backToGeneralArrow = require("../../images/backToGeneralArrow.svg");

const HomeTask = (props) => {
  const [useTask, setUseTask] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState();
  const [validFieldsButtonCreate, setValidFieldsButtonCreate] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogDeleteQuestion, setDialogDeleteQuestion] = useState(false);
  const [dialogDraftQuestion, setDialogDraftQuestion] = useState(false);
  const [validFields, setValidFields] = useState(false);
  const [canBeCreated, setCanBeCreated] = useState(false);

  const [deleteId, setDeleteId] = useState({});
  const [messageQuestion, setMessageQuestion] = useState("");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpenedTabs, setIsOpenedTabs] = useState(false);
  const params = window.location.pathname?.split("/")
  const id = params.length>3 && params[3]

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
    };
    addEventListener("resize", updateScreenSize);
    return () => {
      removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const {
    questions,
    assessmentQuestion,
    formQuestions,
    assessmentName,
    assessmentDescription,
  } = useSelector(assessmentTaskSelectors.getAllState);

  const dispatch = useDispatch();
  const [isNameQuestion, setIsNameQuestion] = useState("Untitled question");

  const onDelete = (e, id, message) => {
    setMessageQuestion(message);
    if (questions.length >= 2) {
      return dispatch(
        DeleteTakeHomeTask({
          id: id,
        })
      );
    } else {
      return dispatch(resetAssessmentTaskState());
    }
  };

  useEffect(() => {
    if (typeof activeTab === "undefined") {
      setActiveTab(0);
    }
  }, [questions, activeTab]);

  const handleClickActiveTab = (e, index) => {
    const { tab } = e.target.dataset;
    if (typeof activeTab === "undefined") {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
    e.stopPropagation();
  };

  const requiredFieldByCreateButton = () =>
    questions.every((homeQuestion) => {
      return homeQuestion.validStatusBar.stepThree === true;
    });

  const onDialog = () => {
    if (
      localStorage.getItem("prevRoute") ===
      "/recruitment/position-workflow/create"
    ) {
      localStorage.setItem("prevRoute", "");
      navigate("/recruitment/position-workflow/create");
    } else {
      navigate("/coding-assessments");
    }  };

  const isEdit = () => {
    const includeId = window.location.pathname.split("/").slice(-1)[0];
    return "take-home-task" === includeId;
  };

  const onSave = (e) => {
    setValidFieldsButtonCreate(true);
    if (requiredFieldByCreateButton() && validFields) {
      setDialog(true);
      const taskId = id
      postData(
        `${API_ASSESSMENT}/api/take-home-task`,
        JSON.stringify({
          ...(taskId && {taskId}),
          name: assessmentName,
          description: assessmentDescription,
          questions: questions?.map((item) => {
            const sendQuestion = {
              name: item.nameQuestion,
              languageId: item.languages?.id,
              packageId: item.packages ? item.packages.id : null,
              databaseId:item?.database?.id,
              database: {
                title: item.database.title,
                description: item.database.description,
                packageId: item.database.packages ? item.database.id : null,
                script: item.database.script,
              },
              description: item.questionDescription,
              candidateInstruction: JSON.stringify(
                draftToHtml(
                  convertToRaw(item.candidateInstruction.getCurrentContent())
                )
              ),
              starterCode: item.starterCode,
              outputType: item.outputType,
              outputDescription: item.outputDescription,
              testCaseInputs: JSON.stringify(
                item.testCaseInputs?.map((item) => {
                  if (item.name || item.description || item.type) {
                    return {
                      name: item.name,
                      description: item.description,
                      type: item.type,
                    };
                  }
                })
              ),
              testcases: item.testCases?.map((testCase) => {
                if (testCase.output) {
                  return {
                    output: testCase.output,
                    input: JSON.stringify(
                      testCase.inputs.flatMap((i, index) => {
                        const testCaseInputType = item?.testCaseInputs?.[index]?.type;
                        let testCaseInputName = item?.testCaseInputs?.[index]?.name;
                        let testCaseInputValue = i.name;

                        if(testCaseInputType === DataTypes.ARRAY_INTEGER || testCaseInputType === DataTypes.ARRAY_STRING){
                          testCaseInputValue = testCaseInputValue.map(el=>DataTypes.ARRAY_INTEGER ? Number(el.value): el.value);
                        }
                        else if(testCaseInputType ===DataTypes.INTEGER){
                          testCaseInputValue = Number(i.name);
                        }

                        return {
                          [testCaseInputName]: testCaseInputValue,
                        };
                      })
                    ),
                  };
                }
              }),
            };
            sendQuestion.testCaseInputs === "[null]"
              ? delete sendQuestion.testCaseInputs
              : sendQuestion;
            sendQuestion.testcases[0] === undefined
              ? delete sendQuestion.testcases
              : sendQuestion;
            sendQuestion.database.title === ""
              ? delete sendQuestion.database
              : sendQuestion;

            return sendQuestion;
          }),
        })
      ).then(
        (res) => {
          postData(`${API_RECRUITMENT}/api/subscription/add-ons`, { addonName: 'assessment', type: 'increase' }).then(data => {
            setData(res);
            setTimeout(() => {
              dispatch(resetAssessmentTaskState());
            }, 2000);
          })
          },
        (err) => {
          console.error(err);
        }
      );
    }
    setTimeout(() => {
      dispatch(resetAssessmentTaskState());
    }, 1500);
  };

  const onSendToDraft = (e) => {
    const taskId = id
    postData(
      `${API_ASSESSMENT}/api/take-home-task/drafts`,
      JSON.stringify({
        ...(taskId && { taskId }),
        name: assessmentName,
        description: assessmentDescription,
        questions: questions?.map((item) => {
          const sendQuestion = {
            name: item.nameQuestion,
            languageId: item.languages?.id,
            packageId: item.packages ? item.packages.id : null,
            databaseId:item?.database?.id ?item?.database?.id:null,
            database: {
              title: item.database.title,
              description: item.database.description,
              packageId: item.database.packages ? item.database.id : null,
              script: item.database.script,
            },
            description: item.questionDescription,
            candidateInstruction: JSON.stringify(
              draftToHtml(
                convertToRaw(item.candidateInstruction.getCurrentContent())
              )
            ),
            starterCode: item.starterCode,
            outputType: item.outputType,
            outputDescription: item.outputDescription,
            testCaseInputs: JSON.stringify(
              item.testCaseInputs?.map((item) => {
                if (item.name || item.description || item.type) {
                  return {
                    name: item.name,
                    description: item.description,
                    type: item.type,
                  };
                }
              })
            ),
            testcases: item.testCases?.map((item) => {
              if (item.output) {
                return {
                  output: item.output,
                  input: JSON.stringify(
                    item.inputs.map((i) => {
                      return {
                        name: i.name,
                      };
                    })
                  ),
                };
              }
            }),
          };
          sendQuestion.testCaseInputs === "[null]"
            ? delete sendQuestion.testCaseInputs
            : sendQuestion;
          sendQuestion.testcases[0] === undefined
            ? delete sendQuestion.testcases
            : sendQuestion;
          sendQuestion.database.title === ""
            ? delete sendQuestion.database
            : sendQuestion;

          return sendQuestion;
        }),
      })
    ).then(
      (res) => {
        setData(res);
        setDialogDraftQuestion(true);
        setTimeout(() => {
          dispatch(resetAssessmentTaskState());
        }, 1500);
        if (
          localStorage.getItem("prevRoute") ===
          "/recruitment/position-workflow/create"
        ) {
          navigate("/recruitment/position-workflow/create");
          localStorage.setItem("prevRoute", "");
        } else {
          navigate("/coding-assessments");
        }      },
      (err) => {
        console.error(err);
      }
    );
    setTimeout(() => {
      dispatch(resetAssessmentTaskState());
    }, 1500);
  };
useEffect(()=>{
  const getDraftInfo = async () => await fetchData(`${API_ASSESSMENT}/api/take-home-task/task/${id}`, () => { }).then(draftData => {
    const draft = draftData?.data
    if (draft) {
      if (draft.name) {
        dispatch(setAssessmentName(draft.name))
      }
      if (draft.description) {
        dispatch(setAssessmentDescription(draft.description))
      }
      if (draft.questions?.length) {
        const ques = draft.questions?.map((item, index) => {
          let instruction = ''
          if (item?.candidateInstruction) {
            const parsedContent = JSON.parse(item.candidateInstruction);
            try {
              let editorState;
              if (parsedContent) {
                const blocksFromHTML = convertFromHTML(parsedContent);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
                editorState = EditorState.createWithContent(contentState);
              } else {
                editorState = EditorState.createEmpty();
              }
              instruction = editorState
            } catch (error) {
              console.log("Error while storing instruction", error)
            }
          }
          const sendQuestion = {
            ...assessmentQuestion,
            id,
            formStep: 1,
            nameQuestion: item.name,
            languages: { id: item.languageId },
            packages: { id: item.packageId },
            database: { id: item.databaseId },
            questionDescription: item.description,
            candidateInstruction: instruction,
            starterCode: item.starterCode,
            outputType: item.outputType,
            outputDescription: item.outputDescription,
            testCaseInputs: 
              item.testCaseInputs ? JSON.parse(item.testCaseInputs)?.map((item) => {
                if (item.name || item.description || item.type) {
                  return {
                    name: item.name,
                    description: item.description,
                    type: item.type,
                  };
                }
              }) : [null]
            ,
            isIncludeTestCases: !!item.testCaseInputs,
            testcases: item.testCases?.map((item) => {
              if (item.output) {
                return {
                  output: item.output,
                  input: JSON.stringify(
                    item.inputs.map((i) => {
                      return {
                        name: i.name,
                      };
                    })
                  ),
                };
              }
            }),
          };
          sendQuestion.testCaseInputs === "[null]"
            ? delete sendQuestion.testCaseInputs
            : sendQuestion;
          sendQuestion.testcases && sendQuestion.testcases[0] === undefined
            ? delete sendQuestion.testcases
            : sendQuestion;
          return sendQuestion;
        })
        dispatch(setQuestions(ques))
      }
    }
  })
  if(id){
    getDraftInfo()
  }
},[])
  
  return (
    <AuthGuard module='assessment' permission={['add','edit']}>
    <div className="create-home-task">
      <div className="create-live-task__container">
        <div className="create-home-task__header">
          <div className="task__header">
            <h1>
                {id ? "Edit a " :"Create a New "}Coding Assessments{" "}
              <button
                className="task__header__select"
                onClick={() => setUseTask(!useTask)}
              >
                <h3 className="task__header-name">- TAKE HOME TASK</h3>
                <img
                  alt="arrow-task"
                  className={`header__user__arrow ${useTask && "turn-arrow"}`}
                  src={arrowTask}
                />
              </button>
            </h1>
            {useTask && (
              <ul className="task">
                <li className="task__take">
                  <Link
                    className="task__item"
                    to="/coding-assessments/live-task"
                  >
                    <h3 className="task__name">LIVE TASK</h3>
                  </Link>
                  <Link
                    className="task__item"
                    to="/coding-assessments/take-home-task"
                  >
                    <h3 className="task__name">TAKE HOME TASK</h3>
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <div className="button-group">
            {screenWidth > 576 ? (
              <>
                <button
                  onClick={() => {
                    dispatch(resetAssessmentTaskState());
                    if (
                      localStorage.getItem("prevRoute") ===
                      "/recruitment/position-workflow/create"
                    ) {
                      localStorage.setItem("prevRoute", "");
                      navigate("/recruitment/position-workflow/create");
                    } else {
                      navigate("/coding-assessments");
                    }                  }}
                  className="button-group__cancel-button-task"
                >
                  Cancel
                </button>
                <div className="button-group__edit-button-task">
                  <button
                    onClick={() => setDialogDraftQuestion(true)}
                    className="send-button-task"
                  >
                    <img
                      alt="send-task"
                      className="pen-icon"
                      src={sendToDraft}
                    />
                    Send to Draft
                  </button>
                </div>
                <button
                  disabled={!canBeCreated}
                  onClick={onSave}
                  className="btn btn--green"
                >
                  {isEdit() ? "Create" : "Save"}
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    dispatch(resetAssessmentTaskState());
                    if (
                      localStorage.getItem("prevRoute") ===
                      "/recruitment/position-workflow/create"
                    ) {
                      localStorage.setItem("prevRoute", "");
                      navigate("/recruitment/position-workflow/create");
                    } else {
                      navigate("/coding-assessments");
                    }                  }}
                  className="button-group__cancel-button-task"
                >
                  <img src={backToGeneralArrow} alt="cancel-arrow" />
                </button>
                <button
                  disabled={!canBeCreated}
                  onClick={onSave}
                  className="btn btn--green button-group__create"
                >
                  {isEdit() ? "Create" : "Save"}
                </button>
                <button
                  onClick={() => setShowDraft(!showDraft)}
                  className={cn("button-group__visibility-switcher", {
                    ["active"]: showDraft,
                  })}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_6557_59203)">
                      <path
                        d="M13 3V10H19L11 21V14H5L13 3Z"
                        stroke="#099C73"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6557_59203">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                {showDraft && (
                  <div className="button-group__edit-button-task">
                    <button
                      onClick={() => setDialogDraftQuestion(true)}
                      className="send-button-task"
                    >
                      <img
                        alt="send-task"
                        className="pen-icon"
                        src={sendToDraft}
                      />
                      Send to Draft
                    </button>
                  </div>
                )}
              </>
            )}

            {dialogDraftQuestion && (
              <DialogDraftQuestion
                onDialog={setDialogDraftQuestion}
                onSendToDraft={onSendToDraft}
              />
            )}
            {dialog && <DialogCreate onDialog={onDialog} />}
          </div>
        </div>
        <div>
          <div className="take-home-tasks">
            {formQuestions && screenWidth > 576 && (
              <div className="go-back-container">
                <div className="go-back-container__button-white">
                  <div style={{ display: "flex" }}>
                    <button
                      onClick={() => dispatch(setFormQuestions(false))}
                      className="go-back-container__back-button"
                    >
                      <img
                        className="go-back-container__back-img"
                        src={backToGeneralArrow}
                        alt="cross-question"
                      />
                      <div className="go-back-container__content">
                        Back to General
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {formQuestions ? (
              <div className="add-question">
                {screenWidth > 576 ||
                (screenWidth < 576 && questions?.length < 2) ? (
                  questions?.map((homeQuestion, index) => {
                    const numberQuestion = questions.findIndex(
                      (findValue) => findValue.id === homeQuestion.id
                    );
                    const isActiveTab = +activeTab === index;
                    return (
                      <button
                        data-tab={index}
                        key={homeQuestion?.id}
                        onClick={(e) => handleClickActiveTab(e, index)}
                        className={`add-question__button ${
                          isActiveTab && screenWidth > 576 ? "active-class" : ""
                        } ${
                          isActiveTab && screenWidth > 576
                            ? "gradient-blue"
                            : "gradient-white"
                        }`}
                      >
                        <section
                          data-tab={index}
                          className="add-question__button__ol-decimal"
                        >
                          <span
                            data-tab={index}
                            className="add-question__button__sharp"
                          >
                            #{numberQuestion + 1}
                          </span>
                          <div data-tab={index} className="add-question__title">
                            {homeQuestion.nameQuestion === ""
                              ? "Untitled question"
                              : homeQuestion.nameQuestion}
                          </div>
                        </section>
                        <button
                          className="add-question__button__cross-img"
                          onClick={(e) => {
                            setDialogDeleteQuestion(true);
                            setDeleteId({
                              event: e,
                              id: homeQuestion?.id,
                              message: `#${numberQuestion + 1} ${
                                homeQuestion.nameQuestion === ""
                                  ? "Untitled question"
                                  : homeQuestion.nameQuestion
                              }`,
                            });
                          }}
                        >
                          {isActiveTab ? (
                            <img src={crossQuestion} alt="cross-question" />
                          ) : (
                            <img
                              src={crossQuestionWhite}
                              alt="cross-question"
                            />
                          )}
                        </button>
                      </button>
                    );
                  })
                ) : (
                  <button
                    data-tab={+activeTab}
                    key={questions[+activeTab || 0].id}
                    onClick={() => setIsOpenedTabs(!isOpenedTabs)}
                    className={`add-question__button ${
                      isOpenedTabs ? "active-class" : ""
                    } ${isOpenedTabs ? "gradient-blue" : "gradient-white"}`}
                  >
                    <section
                      data-tab={+activeTab}
                      className="add-question__button__ol-decimal"
                    >
                      <span
                        data-tab={+activeTab}
                        className="add-question__button__sharp"
                      >
                        #{+activeTab + 1}
                      </span>
                      <div
                        data-tab={+activeTab}
                        className="add-question__title"
                      >
                        {questions[+activeTab || 0].nameQuestion === ""
                          ? "Untitled question"
                          : questions[+activeTab || 0].nameQuestion}
                      </div>
                    </section>
                    <button
                      className="add-question__button__arrow-img"
                      onClick={() => setIsOpenedTabs(!isOpenedTabs)}
                    >
                      <img
                        alt="arrow-task"
                        className={`header__user__arrow ${
                          isOpenedTabs && "turn-arrow"
                        }`}
                        src={arrowTask}
                      />
                    </button>
                  </button>
                )}
                {dialogDeleteQuestion && (
                  <DialogDeleteQuestion
                    onDialogDelete={setDialogDeleteQuestion}
                    onDelete={onDelete}
                    deleteId={deleteId}
                  />
                )}

                {questions.length < 5 && (
                  <div className="cross-add-new-question-wrapper">
                    <AddQuestionTask assessmentQuestion={assessmentQuestion} />
                  </div>
                )}

                {questions.length === 5 && screenWidth > 576 && (
                  <div className="questions-limit">limit reached</div>
                )}

                {isOpenedTabs && (
                  <div className="add-question__dropdown-wrapper">
                    {screenWidth < 576 &&
                      questions?.map((homeQuestion, index) => {
                        const numberQuestion = questions.findIndex(
                          (findValue) => findValue.id === homeQuestion.id
                        );
                        const isActiveTab = +activeTab === index;
                        return (
                          <button
                            data-tab={index}
                            key={homeQuestion?.id}
                            onClick={(e) => {
                              setIsOpenedTabs(!isOpenedTabs);
                              handleClickActiveTab(e, index);
                            }}
                            className={"add-question__button"}
                          >
                            <section
                              data-tab={index}
                              className="add-question__button__ol-decimal"
                            >
                              <span
                                data-tab={index}
                                className="add-question__button__sharp"
                              >
                                #{numberQuestion + 1}
                              </span>
                              <div
                                data-tab={index}
                                className="add-question__title"
                              >
                                {homeQuestion.nameQuestion === ""
                                  ? "Untitled question"
                                  : homeQuestion.nameQuestion}
                              </div>
                            </section>
                            <button
                              className="add-question__button__cross-img"
                              onClick={(e) => {
                                setDialogDeleteQuestion(true);
                                setDeleteId({
                                  event: e,
                                  id: homeQuestion?.id,
                                  message: `#${numberQuestion + 1} ${
                                    homeQuestion.nameQuestion === ""
                                      ? "Untitled question"
                                      : homeQuestion.nameQuestion
                                  }`,
                                });
                              }}
                            >
                              <img src={crossQuestion} alt="cross-question" />
                            </button>
                          </button>
                        );
                      })}
                    {questions.length === 5 && isOpenedTabs && (
                      <div className="questions-limit">limit reached</div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {!formQuestions && (
            <div className="home-general">
              <HomeTaskStepGeneral
                validFields={validFields}
                setValidFields={setValidFields}
              />
            </div>
          )}
          <div>
            {formQuestions &&
              questions?.map((homeQuestion, index) => {
                const isCurrentQuestion =
                  +activeTab === index || activeTab === undefined;
                return (
                  <CSSTransition
                    classNames="my-node"
                    in={isCurrentQuestion}
                    mountOnEnter
                    unmountOnExit
                    key={index}
                    timeout={500}
                  >
                    <div
                      key={homeQuestion?.id}
                      className="take-home-task"
                      style={{ zIndex: `${index + 1}` }}
                    >
                      {homeQuestion.formStep > 0 ? (
                        <ProgressBar
                          setCanBeCreated={setCanBeCreated}
                          homeQuestion={homeQuestion}
                          isIncludeTestCases={homeQuestion?.isIncludeTestCases}
                        />
                      ) : null}
                      {homeQuestion.formStep === 1 && (
                        <HomeTaskStepOne
                          validFields={validFields}
                          setValidFields={setValidFields}
                          requiredFieldByCreateButton={requiredFieldByCreateButton()}
                          isNameQuestion={isNameQuestion}
                          setIsNameQuestion={setIsNameQuestion}
                          homeQuestion={homeQuestion}
                          isCurrentQuestion={isCurrentQuestion}
                        />
                      )}
                      {homeQuestion.formStep === 2 && (
                        <HomeTaskStepTwo
                          validFields={validFields}
                          setValidFields={setValidFields}
                          requiredFieldByCreateButton={requiredFieldByCreateButton()}
                          homeQuestion={homeQuestion}
                          isCurrentQuestionData={isCurrentQuestion}
                        />
                      )}
                      {homeQuestion.formStep === 3 && (
                        <HomeTaskStepThree
                          validFields={validFields}
                          setValidFields={setValidFields}
                          validFieldsButtonCreate={validFieldsButtonCreate}
                          homeQuestion={homeQuestion}
                          isCurrentQuestionData={isCurrentQuestion}
                          isNameQuestion={isNameQuestion}
                        />
                      )}
                    </div>
                  </CSSTransition>
                );
              })}
          </div>
        </div>
      </div>
    </div>
    </AuthGuard>
  );
};

export default React.memo(HomeTask);
