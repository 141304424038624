import {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import ReactHtmlParser from "react-html-parser";

import { TopFiled } from "./TopFiled";
import TableList from "./TableList";
import Dialog from "../Dialog";
import fetchData from "../../hook/fetchData";
import SmallLoader from "../../components/SmallLoader";
import { formatAMPM, LanguagesSet, packageSelect } from "../../config/utils";
import postData from "../../hook/postData";
import { useSelector } from "react-redux";
import { assessmentTaskSelectors } from "../../store/assessmentTask/assessmentTask.selectors";
import { getEnv } from "@urecruits/api";
import { useHasPermission, AuthGuard, PaginationComponent, useSubscription } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_ASSESSMENT, API_RECRUITMENT } = getEnv()

const remove = require("./../../images/delete.svg");
const empty = require("../../images/icon/empty.svg");

const tableFields = [
  { label: "name", name: "Name of the question" },
  { label: "description", name: "Description" },
  { label: "type", name: "Question Type" },
  { label: "languages", name: "Languages" },
  { label: "packages", name: "Packages" },
  { label: "updatedOn", name: "Last Updated on" },
  { label: "status", name: "Status" },
  { label: "actions", name: "Actions" },
];


export const Table = (props) => {

  const { checkUserPermission } = useHasPermission();

  const [refetch, setRefetch] = useState("");
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const idProductRef = useRef();
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [value, setValue] = useState<number>(10);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortingBy, setSortingBy] = useState<Record<string, string>>({
    name: "",
    order: "asc",
  });
  const [checkedChange, setCheckedChange] = useState(false);
  const [itemsRange, setItemsRange] = useState<Record<string, any>>({
    start: 1,
    end: value,
  });
  const items: number[] = [5, 10, 20, 50, 100];
  const [dataLiveTask, setDataLiveTask] = useState([]);
  const [dataHomeTask, setDataHomeTask] = useState([]);
  const [isMouseOverScroll, setIsMouseOverScroll] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loader, setLoader] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [packages, setPackages] = useState([])
  const [languageList, setLanguageList] = useState([])
  const [combineLiveData, setCombineLiveData] = useState([]);

  const { filteredData, hasFilter } = useSelector(assessmentTaskSelectors.getAllState);
  const { checkCurrentPackageType } = useSubscription()
  const hasAssessmentViewPermission = checkUserPermission('assessment', 'view')
  const hasAssessmentPackage = checkCurrentPackageType(['assessment', 'fullcycle'])
  const tableNames = tableFields.map((field) => field.name);
  const filteredHomeData = filteredData
    ?.map((item) => (item.assessmentType === "take-home" ? item : null))
    .filter((r) => r);
  const filteredLiveData = filteredData
    ?.map((item) => (item.assessmentType === "live-task" ? item : null))
    .filter((r) => r);

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
    };
    addEventListener("resize", updateScreenSize);
    return () => {
      removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const handleEnter = () => {
    if (screenWidth > 576) {
      setIsMouseOverScroll(true);
    }
  };

  const handleLeave = () => {
    if (screenWidth > 576) {
      setIsMouseOverScroll(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    hasAssessmentPackage && hasAssessmentViewPermission && fetchData(
      `${API_ASSESSMENT}/api/take-home-task`,
      () => { }
    ).then((data) => {
      setDataHomeTask(data.data)
      setLoader(false);
    });
  }, [refetch]);

  useEffect(() => {
    setLoader(true);
    hasAssessmentPackage && hasAssessmentViewPermission && fetchData(
      `${API_ASSESSMENT}/api/live-coding`,
      () => { }
    ).then((data) => {
      setDataLiveTask(data.data)
      setLoader(false);
    });
  }, [refetch]);

  useEffect(() => {
    setLoader(true);
    hasAssessmentViewPermission && fetchData(
      `${API_ASSESSMENT}/api/live-coding/packages`,
      setPackages
    ).then(() => {
      setLoader(false);
    });
  }, [refetch]);

  useEffect(() => {
    setLoader(true);
    setLanguageList(LanguagesSet)
    setLoader(false);
  }, [refetch]);

  useEffect(() => {
    if (languageList.length > 0) {
      setCombineLiveData(() => {
        return (
          filteredData?.length >= 0 ? filteredLiveData : dataLiveTask
        )?.map((item) => {
          return {
            id: item.id,
            taskId: item.taskId,
            name: item.name,
            description: item.description,
            type: item.questions ? "" : "Live Task",
            languageId: languageList.map((items, indx) =>
              items.id === item.languageId ? languageList[indx]?.name : ""
            ).filter((r) => r),
            packageId:
              item.packageId === packages[item.packageId - 1]?.id
                ? packages[item.packageId - 1].title === "sqlite-jdbc-3.27.2.1" ? "SQLite" : packages[item.packageId - 1].title
                : "",
            updatedOn: formatAMPM(new Date(item.updatedAt)),
            status: item.status,
          };
        });
      })
    }
  }, [languageList, filteredData, dataLiveTask])



  const combineHomeData = (
    filteredData?.length >= 0 ? filteredHomeData : dataHomeTask
  )?.map((item) => {
    return {
      id: item.id,
      taskId: item.taskId,
      name: item.name,
      description: item.description ? item.description : "",
      type: item.questions ? "Home Task" : "",
      languageId: item.questions.map((question) => {
        return LanguagesSet.map((langId) => {
          return langId.id === question.languageId ? langId.name : "";
        }).filter((r) => r);
      }),
      packageId: item.questions
        ?.map((pack) =>
          pack?.id === packageSelect[pack?.id - 1]?.id
            ? packageSelect[pack?.id - 1]?.name
            : ""
        )
        .filter((n) => n),
      updatedOn: formatAMPM(new Date(item.updatedAt)),
      status: item.status,
    };
  });

  const rawData = useMemo(() => {
    if (filteredData?.length >= 0) {
      return [...filteredLiveData, ...filteredHomeData];
    } else {
      return [...dataLiveTask, ...dataHomeTask];
    }
  }, [
    dataHomeTask,
    dataLiveTask,
    filteredHomeData,
    filteredLiveData,
    filteredData?.length,
  ]);

  const allData = useMemo(() => {
    return [...combineHomeData, ...combineLiveData];
  }, [combineHomeData, combineLiveData]);

  //currentItems - each 5/10/20/50/100 tasks in table
  useEffect(() => {
    const endOffset = +value + itemOffset;
    const itemsRanges = {
      start: itemOffset + 1,
      end: endOffset < allData.length ? endOffset : allData.length,
    };
    setItemsRange(itemsRanges);
    setCurrentItems(allData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allData.length / +value));
  }, [itemOffset, value, allData.length, rawData.length, filteredData]);

  //Select all current checkbox
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(currentItems.map((item) => item.taskId.toString()));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  //Select all data items
  const handleSelectAllItems = (e) => {
    setCurrentItems(currentItems);
    setIsCheckAll(!isCheckAll);
    setIsCheck(allData.map((item) => item.taskId.toString()));
    if (isCheckAll) {
      // setIsCheck(Object.keys(data).map((item, id) => id.toString()));
      setIsCheckAll(true);
    }
  };

  const handleClick = (e, taskId) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, taskId.toString()]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== taskId));
    }
  };

  //local search (need add condition for numbers)
  const onSearchChange = useCallback(() => {
    let dataUpgrade = allData.filter((item) =>

      Object.values(item).some((value) => {
        const cin = value
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLocaleLowerCase())
        return cin
      }
      )
    );
    setPageCount(Math.ceil(dataUpgrade.length / +value));

    if (searchValue.length < 0) {
      setCurrentItems(currentItems);
    } else {
      const itemsRanges = {
        start: itemOffset + 1,
        end: dataUpgrade.length,
      };
      setItemsRange(itemsRanges);
      setCurrentItems(dataUpgrade.slice(itemsRanges.start - 1, itemsRanges.end));
    }

  }, [allData, currentItems, searchValue, value]);

  //change page in pagination
  const handlePageClick = useCallback(
    (e) => {
      const newOffset = (e.selected * +value) % allData.length;

      setItemOffset(newOffset);
      return onSearchChange();
    },
    [allData.length, onSearchChange, value]
  );

  //TODO: need check sort state
  const sortTableData = useMemo(() => {
    if (sortingBy.name) {
      const reversed = sortingBy.order === "asc" ? 1 : -1;

      allData.sort((a, b) => {
        Array.isArray(a[sortingBy.name])
          ? a[sortingBy.name].join(",")
          : Array.isArray(a[sortingBy.name]);
        Array.isArray(b[sortingBy.name])
          ? b[sortingBy.name].join(",")
          : Array.isArray(b[sortingBy.name]);

        return reversed * a[sortingBy.name]?.localeCompare(b[sortingBy.name]);
      });
    }

    return allData.slice(
      (currentPage - 1) * +value,
      (currentPage - 1) * +value + +value
    );
  }, [sortingBy, allData, currentPage, value]);

  const onHandleDeleteDialog = (choose) => {
    handleDialog(choose);
  };

  const handleDialog = (choose?: boolean) => {
    if (dialog.id && choose === true) {
      const data = allData
        .map((item) => {
          return item.taskId === dialog.id
            ? {
              id: item.id,
              type: item.type === "Home Task" ? "take-home" : "live-task",
              status: item.status === "ACTIVE" ? "ACTIVE" : "DRAFT",
            }
            : null;
        })
        .filter((r) => r);
      postData(
        `${API_ASSESSMENT}/api/coding-assessment/delete`,
        data
      ).then(() => {
        setRefetch(new Date().toString());
        postData(`${API_RECRUITMENT}/api/subscription/add-ons`, { addonName: 'assessment', type: 'decrease' }).then(data => { })
        const endOffset = +value + itemOffset;
        return setCurrentItems(allData.slice(itemOffset, endOffset));
      });
    } else if (choose === false) {
      return setDialog({ isLoading: false });
    }
    const endOffset = +value + itemOffset;
    return setCurrentItems(allData.slice(itemOffset, endOffset));
  };

  const handleRemove = (id) => {
    const index = allData.findIndex((item) => {
      return item.taskId === id;
    });

    const nameQuestion = `Are you sure you want to delete this question: <b>${allData[index].name}</b>? This action cannot be undone`;
    const nameQuestionHtml = ReactHtmlParser(nameQuestion);
    idProductRef.current = id;

    if (allData[index].taskId === id) {
      return setDialog({
        message: nameQuestionHtml,
        isLoading: true,
        nameProduct: "",
        id: id,
      });
    }
  };

  const handleRemoveChecked = () => {
    const rows = currentItems.filter(
      (el) => !isCheck.includes(el.taskId.toString())
    );

    const data = isCheck
      .map((itm) => {
        return currentItems
          .map((i) => {
            return itm === i.taskId
              ? {
                id: i.id,
                type: i.type === "Home Task" ? "take-home" : "live-task",
                status: i.status === "ACTIVE" ? "ACTIVE" : "DRAFT",
              }
              : null;
          })
          .filter((r) => r)[0];
      })
      .filter((r) => r);

    postData(
      `${API_ASSESSMENT}/api/coding-assessment/delete`,
      data
    ).then(() => {
      setRefetch(new Date().toString());
    });
    setIsCheck([]);
    if (isCheckAll) {
      setIsCheckAll(!isCheckAll);
    }
    return setCurrentItems(rows);
  };

  return (
    <>
      <TopFiled
        tableNames={tableNames}
        setSearchValue={setSearchValue}
        onSearchChange={onSearchChange}
        searchValue={searchValue}
      />
      <div
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        className="coding-assessments__container"
      >
        {loader ? (
          <div className="coding-assessments__loader">
            <SmallLoader />
          </div>
        ) : (
          <section className="coding-assessments__wrapper">
            {!!rawData?.length && !!allData?.length ? (
              <div className="coding-assessments__table">
                <TableList
                  rawData={rawData}
                  isMouseOverScroll={isMouseOverScroll}
                  tableFields={tableFields}
                  checkedChange={checkedChange}
                  currentItems={currentItems}
                  isCheck={isCheck}
                  handleClick={handleClick}
                  handleRemove={handleRemove}
                  isCheckAll={isCheckAll}
                  handleSelectAll={handleSelectAll}
                  handlePageClick={handlePageClick}
                  handleRemoveChecked={handleRemoveChecked}
                  remove={remove}
                  handleSelectAllItems={handleSelectAllItems}
                  itemsRange={itemsRange}
                  pageCount={pageCount}
                  items={items}
                  setValue={setValue}
                  setSortingBy={setSortingBy}
                  setCurrentItems={setCurrentItems}
                  sortTableData={sortTableData}
                />
                {dialog?.isLoading && (
                  <AuthGuard module='assessment' permission='delete'>
                    <Dialog
                      nameProduct={dialog?.nameProduct}
                      onDialog={onHandleDeleteDialog}
                      message={dialog?.message}
                    />
                  </AuthGuard>
                )}
              </div>
            ) : (
              <div className="empty border">
                <div className="empty__container">
                  <div className="empty-img">
                    <img src={empty} alt="empty" />
                  </div>
                  <div className="empty-title">
                    <p>Table is empty</p>
                  </div>
                  <div className="empty-title__small">
                    <p>
                      Coding assessments table is empty{checkUserPermission('assessment', 'add') && ", create your first assessment."}
                    </p>
                  </div>
                  <AuthGuard module='assessment' permission='add'>
                    <button
                      onClick={props.handleDialogType}
                      className="btn btn--green"
                    >
                      Add New
                    </button>
                  </AuthGuard>
                </div>
              </div>
            )}
          </section>
        )}
      </div>
      <PaginationComponent
        limit={value}
        currentPage={currentPage}
        totalCount={currentItems.length}
        setCurrentPage={(e) => {
          setCurrentPage(e)
          handlePageClick(e)
        }}
        setLimit={value => {
          setValue(value)
        }}
        filters={[]}
      />
    </>
  );
};
