import { useEffect, useState } from "react";
import { Table } from "./table/Table";
import DialogQuestion from "./tasks/DialogQuestion";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

export const CodingAssessments = (props) => {


  const [value, setValue] = useState();
  const [dialog, setDialog] = useState({
    isLoading: false,
    liveTask: false,
  });

  const handleDialogType = () => {
    setDialog(
      Object.assign({}, dialog, {
        isLoading: true,
      })
    );
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onDialog = (e) => {
    setDialog(
      Object.assign({}, dialog, {
        isLoading: false,
      })
    );
  };
  useEffect(()=>{
		localStorage.setItem("prevRoute","")
	},[])

  return (
    <AuthGuard module='assessment' permission={['view','add']}>
    <section className="coding-assessments">
      <section className="coding-assessments__header">
        <div>
          <h2>Coding Assessments</h2>
        </div>
        <AuthGuard module='assessment' permission='add'>
          <button onClick={handleDialogType} className="add-btn add-btn--green">
            Add New
          </button>
        </AuthGuard>
      </section>
      <section className="coding-assessments__body">
      <AuthGuard module={'assessment'} permission='view'>
        <Table handleDialogType={handleDialogType} />
      </AuthGuard>
      </section>
      {/* <section>
        <PortalDiv />
        <SearchPortal />
      </section> */}
      {dialog.isLoading  && (
        <AuthGuard module='assessment' permission='add'>
          <DialogQuestion onChange={onChange} onDialog={onDialog} value={value} />
        </AuthGuard>
      )}
    </section>
    </AuthGuard>
  );
};
